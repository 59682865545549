<template>
<div class="contact dirr">
    <div class="titles" style="width:100%; display: flex; justify-content: center;" v-if="skeleton">
        <v-skeleton-loader  width="300" min-width="300" type="paragraph, paragraph"></v-skeleton-loader>
    </div>
    <div class="titles" v-if="!skeleton">
        <p class="tl">{{ $t("alkafeel_contact") }}</p>
        <p class="tl">{{ $t("for_Questions") }}</p>
        <p class="tl">{{ $t("for_Questions_Phones") }}</p>
    </div>
    <div class="Kafeelinfo" v-if="skeleton">
        <v-layout row>
            <v-flex xs12 sm6 md4 lg4 style="padding:10px;">
                <v-skeleton-loader  width="300" min-width="300" type="card"></v-skeleton-loader>
            </v-flex>
            <v-flex xs12 sm6 md4 lg4 style="padding:10px;">
                <v-skeleton-loader  width="300" min-width="300" type="card"></v-skeleton-loader>
            </v-flex>
            <v-flex xs12 sm6 md4 lg4 style="padding:10px;">
                <v-skeleton-loader  width="300" min-width="300" type="card"></v-skeleton-loader>
            </v-flex>
        </v-layout>
    </div>
    <div class="Kafeelinfo" v-if="!skeleton">
        <v-layout row>
            <v-flex xs12 sm6 md4 lg4 style="padding:10px;">
                <div class="phones">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M35.4688 0.4375H4.53125C2.1582 0.4375 0.3125 2.37109 0.3125 4.65625V35.5938C0.3125 37.9668 2.1582 39.8125 4.53125 39.8125H35.4688C37.7539 39.8125 39.6875 37.9668 39.6875 35.5938V4.65625C39.6875 2.37109 37.7539 0.4375 35.4688 0.4375ZM33.9746 27.5078L32.6562 33.2207C32.5684 33.8359 32.041 34.1875 31.4258 34.1875C17.3633 34.1875 5.9375 22.8496 5.9375 8.69922C5.9375 8.08398 6.37695 7.64453 6.9043 7.46875L12.6172 6.15039C12.7051 6.15039 12.793 6.15039 12.9688 6.0625C13.4961 6.15039 13.9355 6.41406 14.1113 6.94141L16.748 13.0938C16.8359 13.1816 16.8359 13.3574 16.9238 13.5332C16.8359 13.9727 16.6602 14.3242 16.3965 14.5879L13.0566 17.3125C15.0781 21.6191 18.5059 25.0469 22.8125 27.0684L25.5371 23.7285C25.8008 23.4648 26.1523 23.2891 26.5918 23.2012C26.7676 23.2891 26.9434 23.2891 27.0312 23.377L33.1836 26.0137C33.7109 26.1895 33.9746 26.6289 34.0625 27.1562C33.9746 27.332 33.9746 27.4199 33.9746 27.5078Z" fill="#B1BD52"/>
                    </svg>
                    <p class="tl mainTl">{{ $t("Phone_Num") }}</p>
                    <p class="tl" dir="ltr" v-for="i in contact.slice(0,2)" :key="i" v-html="format_Phone(i.contact)"></p>
                </div>
            </v-flex>
            <v-flex xs12 sm6 md4 lg4 style="padding:10px;">
                <div class="email">
                    <svg width="46" height="34" viewBox="0 0 46 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M44.6211 11.4121C42.5996 12.9941 40.0508 14.9277 31.0859 21.4316C29.3281 22.75 26.0762 25.6504 23 25.6504C19.8359 25.6504 16.6719 22.75 14.8262 21.4316C5.86133 14.9277 3.3125 12.9941 1.29102 11.4121C0.939453 11.1484 0.5 11.4121 0.5 11.8516V29.7812C0.5 32.1543 2.3457 34 4.71875 34H41.2812C43.5664 34 45.5 32.1543 45.5 29.7812V11.8516C45.5 11.4121 44.9727 11.1484 44.6211 11.4121ZM23 22.75C25.0215 22.8379 27.9219 20.2012 29.416 19.1465C41.1055 10.709 41.9844 9.91797 44.6211 7.80859C45.1484 7.45703 45.5 6.8418 45.5 6.13867V4.46875C45.5 2.18359 43.5664 0.25 41.2812 0.25H4.71875C2.3457 0.25 0.5 2.18359 0.5 4.46875V6.13867C0.5 6.8418 0.763672 7.45703 1.29102 7.80859C3.92773 9.91797 4.80664 10.709 16.4961 19.1465C17.9902 20.2012 20.8906 22.8379 23 22.75Z" fill="#B1BD52"/>
                    </svg>
                    <p class="tl mainTl">{{ $t("account_email") }}</p>
                    <a style="text-decoration: none" v-for="i in contact.slice(2)" :href="'mailto:' + i.contact" :key="i"><p class="tl" >{{ i.contact }}</p></a>
                </div>
            </v-flex>
            <v-flex xs12 sm6 md4 lg4 style="padding:10px;">
                <!-- <div class="location" @click="show('main', $t('karbala_oldcity'), '32.617298,44.036298')" style="cursor: pointer;"> -->
                <div class="location">
                    <svg width="34" height="46" viewBox="0 0 34 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.2422 44.7461C16.0332 45.9766 17.8789 45.9766 18.6699 44.7461C31.502 26.2891 33.875 24.3555 33.875 17.5C33.875 8.18359 26.3164 0.625 17 0.625C7.5957 0.625 0.125 8.18359 0.125 17.5C0.125 24.3555 2.41016 26.2891 15.2422 44.7461ZM17 24.5312C13.0449 24.5312 9.96875 21.4551 9.96875 17.5C9.96875 13.6328 13.0449 10.4688 17 10.4688C20.8672 10.4688 24.0312 13.6328 24.0312 17.5C24.0312 21.4551 20.8672 24.5312 17 24.5312Z" fill="#B1BD52"/>
                    </svg>
                    <p class="tl mainTl">{{ $t("Geo_Location") }}</p>
                    <p class="tl" >{{ $t("karbala_oldcity") }}</p>
                </div>
            </v-flex>
        </v-layout>
    </div>
    <div class="message" v-if="!skeleton">
        <v-layout row>
            <v-flex xs12 sm6 md4 lg4 style="padding:10px;">
                <div class="googleMap">
                    <GmapMap :options="mapOptions" :center="{lat:32.617298, lng:44.036298}" :zoom="17" map-type-id="roadmap" style="width: 100%; height: 100%">
                        <gmap-marker :position="{lat:32.617298, lng:44.036298}"></gmap-marker>
                    </GmapMap>
                </div>
                <p class="tl mainTl d-flex d-sm-none">{{ $t("contact_with_form") }}</p>
                <!-- <v-img class="d-none d-sm-flex" lazy-src="/assets/img/ContactUs.jpg" src="/assets/img/ContactUs.jpg" style="border-radius: 4px;"></v-img> -->
            </v-flex>
            <v-flex xs12 sm6 md8 lg8 style="padding:10px;">
                <v-form class="contactForm" ref="form" v-model="valid">
                    <div class="F_Cont">
                        <v-text-field @keyup.enter.native="send()" v-model="message_i.name" background-color="#F3F3F3" :rules="[rules.required]" :placeholder="$t('account_name')" outlined></v-text-field>
                        <span class="d-none d-sm-flex"></span>
                        <v-text-field @keyup.enter.native="send()" v-model="message_i.subject" background-color="#F3F3F3" :rules="[rules.required]" :placeholder="$t('contact_subject')" outlined></v-text-field>
                    </div>
                    <v-textarea v-model="message_i.message" style="width:100%" background-color="#F3F3F3" :rules="[rules.required]" :placeholder="$t('msg_contents')" outlined></v-textarea>
                    <div class="F_Cont">
                        <v-text-field @keyup.enter.native="send()" class="phone" v-model="message_i.phone" background-color="#F3F3F3" :placeholder="$t('Phone_Num_optional')" outlined></v-text-field>
                    </div>
                    <div class="F_Cont">
                        <v-text-field @keyup.enter.native="send()" v-model="message_i.email" background-color="#F3F3F3" :rules="[rules.required,rules.email]" :placeholder="$t('account_email')" outlined></v-text-field>           
                        <span class="d-none d-sm-flex"></span>
                        <v-btn style="align-self: center;" min-width="50%" height="52" class="tl" :loading="loading" color="rgba(177, 189, 82, 1)" @click="send()">
                            <p class="alistSub tl">{{ $t('action_send') }}</p>
                        </v-btn>
                    </div>
                </v-form>
            </v-flex>
        </v-layout>
    </div>
    <div class="divisions" v-if="$i18n.locale == 'ar' && !skeleton">
        <p class="tl">{{ $t("holy_shrine_divisions") }}</p>
        <p class="tl">{{ $t("click_div_info") }}</p>
        <div class="items">
            <v-layout row>
                <v-flex xs12 sm6 md4 lg4 v-for="i in divisions" :key="i" style="padding:10px;">
                    <div class="item" @click="show(i.did, i.address, i.coordinates)">
                        <img :src='i.logo' alt="">
                        <span></span>
                        <p class="tl">{{ i.ar_title }}</p>
                    </div>
                </v-flex>
            </v-layout>
        </div>
    </div>
    <v-dialog v-model="dialog" max-width="1000" class="dirr">
        <v-card class="Contact_dialog">
            <v-btn class="closeBtn" @click="dialog = false" color="red" style="margin:20px 20px 0 20px;" fab small dark>
                <span>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.898438 0.0796127C0.242188 0.26702 -0.164062 1.10254 0.078125 1.76628C0.125 1.88341 1.96094 3.78091 4.16406 5.98295L8.16406 9.98878L4.20312 13.9478C0.171875 17.977 0 18.18 0 18.7345C0 19.2889 0.5 19.8667 1.04688 19.9604C1.76562 20.0775 1.58594 20.2181 5.96094 15.8609L10 11.8238L14.0469 15.8609C18.4141 20.2181 18.2344 20.0775 18.9531 19.9604C19.5 19.8667 20 19.2889 20 18.7345C20 18.18 19.8281 17.977 15.7969 13.9478L11.8359 9.98878L15.8359 5.98295C18.0391 3.78091 19.875 1.88341 19.9219 1.76628C20.1406 1.17282 19.8594 0.470045 19.2969 0.173317C18.8984 -0.0375164 18.625 -0.053134 18.2188 0.110847C18.0234 0.196743 16.6953 1.46955 13.9688 4.19477L10 8.15375L6.03906 4.19477C3.30469 1.46955 1.97656 0.196743 1.78125 0.110847C1.46094 -0.0218994 1.25 -0.0297082 0.898438 0.0796127Z" fill="white"/>
                    </svg>
                </span>
            </v-btn>
            <div class="Thead d-none d-sm-flex">
                <v-layout row>
                    <v-flex xs12 sm3 md3 lg3 style="padding:10px;">
                        <p class="tl">{{ $t("photos_in_section") }}</p>
                    </v-flex>
                    <v-flex xs12 sm3 md3 lg3 style="padding:10px;">
                        <p class="tl">{{ $t("Phone_Num") }}</p>
                    </v-flex>
                    <v-flex xs12 sm3 md3 lg3 style="padding:10px;">
                        <p class="tl">{{ $t("account_email") }}</p>
                    </v-flex>
                    <v-flex xs12 sm3 md3 lg3 style="padding:10px;">
                        <p class="tl">{{ $t("address") }}</p>
                    </v-flex>
                </v-layout>
            </div>
            <div class="Tbody">
                <div class="divInfo" v-for="i in divInfo" :key="i">
                    <v-layout row>
                        <v-flex xs12 sm3 md3 lg3 style="padding:10px;">
                            <p class="tl">{{ i.ar_title }}</p>
                        </v-flex>
                        <v-flex xs12 sm3 md3 lg3 style="padding:10px;">
                            <p class="tl" dir="ltr" v-html="format_Phone(i.phone)"></p>
                        </v-flex>
                        <v-flex xs12 sm3 md3 lg3 style="padding:10px;">
                            <p class="tl" dir="ltr" v-html="i.email.replaceAll(',', '<br/>')"></p>
                        </v-flex>
                        <v-flex xs12 sm3 md3 lg3 style="padding:10px;">
                            <p class="tl" v-html="i.mail"></p>
                        </v-flex>
                    </v-layout>
                </div>
                <v-layout row>
                    <v-flex xs12 sm12 md12 lg12 style="">
                        <p class="tl adress">{{ devAddress }}</p>
                    </v-flex>
                    <v-flex xs12 sm12 md12 lg12 style="padding:10px 20px 20px 20px;">
                        <div class="gmap">
                            <GmapMap :options="mapOptions" :center="coordinates" :zoom="17" map-type-id="roadmap" style="width: 100%; height: 100%">
                                <gmap-marker :position="coordinates"></gmap-marker>
                            </GmapMap>
                        </div>
                    </v-flex>
                </v-layout>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn style="letter-spacing: normal;" color="red darken-1" text @click="dialog = false">{{$t("close")}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>


<script>

import Swal from "sweetalert2";
export default {
    data() {
        return {
            contact : [],
            divisions : [],
            divInfo : [],
            mapOptions : {
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                disableDefaultUI: false
            },
            coordinates : {},
            devAddress : '',
            dialog: false,
            message_i:{name:'', email:'', subject:'', message:'', phone:''},
            skeleton : true,
            valid: false,
            rules: {
                required: value => !!value || this.$t("required_field"),
                email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t("alert_wrong_email"),
            },
        }
    },
    components: {
    },
    directives: {
    },
    computed: {
    },
    mounted() {
        document.title =this.$t("index_callus"); 
    }, 
    created () {
        this.getKafeelinfo();
    },
    methods:{
        getKafeelinfo () {
            this.$axios.get("contact/alkafeel_info", {})
            .then(res => {
                this.contact = res.data;
                setTimeout(() => this.skeleton = false, 500);
                //console.log(res.data);
                this.getdivinfo();
            })
            .catch(() => {
            });
        },
        getdivinfo () {
            this.$axios.get("contact/parts_info", {})
            .then(res => {
                this.divisions = res.data;
                //console.log(res.data);
            })
            .catch(() => {
            });
        },
        send() {
            if (this.$refs.form.validate()) {
                this.loader = 'loading';
                //if (this.message_i.email == '') {var query = {name:this.message_i.name, subject:this.message_i.subject, message:this.message_i.message,}}
                //else {var query = this.message_i}
                var query = {name:this.message_i.name, subject:this.message_i.subject, message:this.message_i.message, email:this.message_i.email, phone:this.message_i.phone};
                this.$axios.post('contact/storeMsg', query).then(res => {
                    Swal.fire({
                        title: this.$t("msg_message_sent"),
                        text: "",
                        icon: "success",
                        timer: 3000,
                        confirmButtonColor: '#B1BD52',
                    });
                    this.message_i = {};
                    console.log(res.data);
                }).catch(() => {
                });
            }
        },
        show(x,y,z) {
            this.coordinates = this.format_coordinates(z);
            this.devAddress = y;
            this.$axios.get("contact/sub_devisions/"+x, {})
            .then(res => {
                this.divInfo = res.data;
                //console.log(res.data);
                this.dialog = true;
            })
            .catch(() => {
            });
        },
        format_coordinates (x) {
            var i = x.split(',');
            return {lat:parseFloat(i[0]), lng:parseFloat(i[1])};
        },
        format_Phone (i) {
            return i.replaceAll(',', '<br/>').replaceAll('00964', '+964-');
        }
    },
    filters: {
        subStrTitle: function (string) {if (string != null) {return string.substring(0, 65) + '...';}},
    }
}
</script>